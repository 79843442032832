import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  bounceFieldRawToClient,
  IClientBounceField,
  IClientBounceFieldOption,
  IRawBounceField,
} from '@shared/helpers/converters/bouncefield.ts';
import { connectorRawToClient, IRawConnector } from '@shared/helpers/converters/connector.ts';
import {
  docTypeRawToClient,
  IClientDocType,
  IClientDocTypeSubType,
  IRawDocTypeField,
} from '@shared/helpers/converters/doctype.ts';
import { endpointRawToClient, IRawEndpoint } from '@shared/helpers/converters/endpoint.ts';
import {
  fieldTypeRawToClient,
  IClientFieldType,
  IRawFieldType,
} from '@shared/helpers/converters/fieldtype.ts';
import { IClientInbox, inboxRawToClient, IRawInbox } from '@shared/helpers/converters/inbox.ts';
import {
  IRawMasterdataTable,
  IRawMasterdataVersion,
  masterdataRawToClient,
} from '@shared/helpers/converters/masterdata.ts';
import { IClientMetadata, IRawMetadata, metadataRawToClient } from '@shared/helpers/converters/metadata.ts';
import { IRawTag } from '@shared/helpers/converters/tag.ts';
import { IClientTenant } from '@shared/helpers/converters/tenant.ts';
import { IRawWebhook, webhookRawToClient } from '@shared/helpers/converters/webhook.ts';
import { getCurrentEnvCode, getUserToken } from '@shared/helpers/helpers';
import { AdminEndpoint, AdminWebhook, DocTypeRule, WebhookServerSideValue } from '@shared/models/admin';
import { ActionTypeOption, TagType } from '@shared/models/document';
import { InboxInfo } from '@shared/models/inbox';
import { UserAccount } from '@shared/models/user';
import { AxiosRequestConfig } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import cloneDeep from 'lodash/cloneDeep';
import { api, db } from './setup/firebase-setup';
import { AppThunk } from './store';
import { subsSlice } from './subsSlice';

export interface AdminState {
  users: UserAccount[];
  tenantDetails?: IClientTenant;
  inboxes: IClientInbox[];
  webhooks?: IRawWebhook[];
  endpoints?: IRawEndpoint[];
  connectors?: IRawConnector[];
  inboxDocTypes?: IClientDocType[];
  webhookServerSideValues?: WebhookServerSideValue[];
  inboxEntityTypes?: IClientFieldType[];
  inboxMasterdataTables?: IRawMasterdataTable[];
  inboxMasterdataVersions?: IRawMasterdataVersion[];
  inboxActionTypes?: IClientBounceField[];
  inboxMetadataTypes?: IClientMetadata[];
  inboxTagTypes?: TagType[];
  inboxApprovalChecks?: string[];
  inboxDetails?: InboxInfo;
  inboxSettingsLoading?: boolean;
  isSettingApplying?: boolean;
  activeInboxId?: string;
  originalInboxId?: string;
  activeInboxName?: string;
  activeDocTypeId?: string;
  activeDocTypeList?: string[];
  activeSubTypeId?: string;
  activeSubType?: IClientDocTypeSubType;
  activeActionTypeOption?: ActionTypeOption;
  activeActionTypeOptions?: ActionTypeOption[];
  activeEntityTypeId?: string;
  activeEntityTypeList?: IClientFieldType[];
  activeMetadataTypeId?: string;
  activeMasterdataId?: string;
  activeTagTypeId?: string;
  activeTagTypeList?: TagType[];
  activeActionTypeId?: string;
  activeConnectorId?: string;
  isInboxesLoading?: boolean;
  activeWebhookValues?: any[];
  newTempSubTypes?: IClientDocTypeSubType[];
  newTempDocType?: IClientDocType;
  newTempBounceOptions?: IClientBounceFieldOption[];
  newTempBounceField?: IClientBounceField;
}

const initialState: AdminState = { users: [], inboxes: [], connectors: [] };

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setUsers: (state, { payload }) => {
      state.users = payload;
    },
    setUser: (state, { payload }) => {
      const index = state.users.findIndex((user) => user.id === payload.id);
      const updated = [...state.users];
      updated[index] = payload;
      state.users = updated;
    },
    setNewTempSubTypes: (state, { payload }) => {
      state.newTempSubTypes = payload;
    },
    setNewTempDocType: (state, { payload }) => {
      state.newTempDocType = payload;
    },
    setNewTempBounceOptions: (state, { payload }) => {
      state.newTempBounceOptions = payload;
    },
    setNewTempBounceField: (state, { payload }) => {
      state.newTempBounceField = payload;
    },
    setInboxes: (state, { payload }) => {
      state.inboxes = payload;
    },
    setConnectors: (state, { payload }) => {
      state.connectors = payload;
    },
    setIsInboxesLoading: (state, { payload }) => {
      state.isInboxesLoading = payload;
    },

    setInboxSettingsLoading: (state, { payload }) => {
      state.inboxSettingsLoading = payload;
    },

    setIsSettingApplying: (state, { payload }) => {
      state.isSettingApplying = payload;
    },

    setInboxDocTypes: (state, { payload }) => {
      state.inboxDocTypes = payload;
    },

    setWebhooks: (state, { payload }) => {
      state.webhooks = payload;
    },
    setEndpoints: (state, { payload }) => {
      state.endpoints = payload;
    },

    setInboxEntityTypes: (state, { payload }) => {
      state.inboxEntityTypes = payload;
    },

    setInboxMetadataTypes: (state, { payload }) => {
      state.inboxMetadataTypes = payload;
    },

    setInboxTagTypes: (state, { payload }) => {
      state.inboxTagTypes = payload;
    },
    setInboxApprovalChecks: (state, { payload }) => {
      state.inboxApprovalChecks = payload;
    },
    setInboxMasterdataTables: (state, { payload }) => {
      state.inboxMasterdataTables = payload;
    },
    setInboxMasterdataVersions: (state, { payload }) => {
      state.inboxMasterdataVersions = payload;
    },

    setInboxActionTypes: (state, { payload }) => {
      state.inboxActionTypes = payload;
    },

    setActiveActionTypeOption: (state, { payload }) => {
      state.activeActionTypeOption = payload;
    },

    setActiveActionTypeOptions: (state, { payload }) => {
      state.activeActionTypeOptions = payload;
    },

    setActiveInboxId: (state, { payload }) => {
      state.activeInboxId = payload;
    },
    setOriginalInboxId: (state, { payload }) => {
      state.originalInboxId = payload;
    },

    setActiveInboxName: (state, { payload }) => {
      state.activeInboxName = payload;
    },

    setTenantDetails: (state, { payload }) => {
      state.tenantDetails = payload;
    },
    setActiveDocTypeId: (state, { payload }) => {
      state.activeDocTypeId = payload;
    },
    setActiveDocTypeList: (state, { payload }) => {
      state.activeDocTypeList = payload;
    },

    setActiveMasterdataId: (state, { payload }) => {
      state.activeMasterdataId = payload;
    },

    setActiveActionTypeId: (state, { payload }) => {
      state.activeActionTypeId = payload;
    },

    setActiveSubTypeId: (state, { payload }) => {
      state.activeSubTypeId = payload;
    },
    setWebhookServerSideValues: (state, { payload }) => {
      state.webhookServerSideValues = payload;
    },

    setActiveEntityTypeId: (state, { payload }) => {
      state.activeEntityTypeId = payload;
    },
    setActiveEntityTypeList: (state, { payload }) => {
      state.activeEntityTypeList = payload;
    },

    setActiveMetadataTypeId: (state, { payload }) => {
      state.activeMetadataTypeId = payload;
    },

    setActiveTagTypeId: (state, { payload }) => {
      state.activeTagTypeId = payload;
    },
    setActiveTagTypeList: (state, { payload }) => {
      state.activeTagTypeList = payload;
    },

    setActiveConnectorId: (state, { payload }) => {
      state.activeConnectorId = payload;
    },
  },
});

export const adminValidTopologyTypesSelector = createSelector(
  [(state) => state.admin.inboxes, (state) => state.admin.activeInboxId],
  (inboxes, inboxId) => {
    const currentInbox = inboxes.find((e) => e.id === inboxId);
    if (currentInbox?.settings.mailroom) {
      return [
        { value: 'bundle', label: 'Bundle' },
        { value: 'document', label: 'Document' },
        { value: 'mail', label: 'Mail' },
      ];
    }
      return [
        { value: 'bundle', label: 'Bundle' },
        { value: 'document', label: 'Document' },
      ];
  }
);

export const clientConnectorsSelector = createSelector(
  [(state) => state.admin.connectors, (state) => state.admin.webhookServerSideValues],
  (connectors, webhookServerSideValues) => {
    if (!connectors || !webhookServerSideValues) {
      return [];
    }
    let mappedConnectors = connectors?.map((connector) => {
      const mapped = connectorRawToClient(connector, webhookServerSideValues);
      return mapped;
    });
    mappedConnectors = mappedConnectors.filter((e) => e != null);
    return mappedConnectors;
  }
);

export const clientEndpointsSelector = createSelector(
  [
    (state) => state.admin.endpoints,
    (state) => state.admin.webhookServerSideValues,
    (state) => state.admin.connectors,
  ],
  (endpoints: IRawEndpoint[], webhookServerSideValues, connectors) => {
    if (!endpoints || !webhookServerSideValues || !connectors) {
      return [];
    }
    const mappedEndpoints = endpoints?.map((endpoint) => {
      const mapped = endpointRawToClient(endpoint, connectors, webhookServerSideValues);
      return mapped;
    });
    return mappedEndpoints.filter((e) => e != null);
  }
);

export const clientWebhookSelector = createSelector(
  [(state) => state.admin.webhooks, clientEndpointsSelector],
  (webhooks, endpoints) => {
    if (!webhooks || !endpoints || !endpoints.length) {
      return [];
    }
    const mappedWebhooks = webhooks?.map((webhook) => {
      return webhookRawToClient(webhook, endpoints);
    });
    return mappedWebhooks;
  }
);

export const activeClientMasterdataSelector = createSelector(
  [
    (state) => state.admin.inboxMasterdataTables as IRawMasterdataTable[],
    (state) => state.admin.inboxMasterdataVersions,
    (state) => state.admin.activeMasterdataId,
  ],
  (tables, versions, activeId) => {
    const activeTable = tables?.find((e) => e.id === activeId);
    if (activeTable) return masterdataRawToClient(activeTable, versions ?? []);
  }
);

export const activeClientDocTypeSelector = createSelector(
  [(state) => state.admin.inboxDocTypes, (state) => state.admin.activeDocTypeId],
  (docTypes, activeDocTypeId) => {
    const docType = docTypes?.find((e) => e.id === activeDocTypeId);
    if (docType) return docType;
  }
);

export const activeInboxSelector = createSelector(
  [(state) => state.admin.inboxes, (state) => state.admin.activeInboxId],
  (inboxes, activeInboxId) => {
    return inboxes.find((e) => e.id === activeInboxId) as InboxInfo;
  }
);

export const listenUsers = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const query = collection(db, `tenants/${tenantId}/users`);

  return onSnapshot(query, (res) => {
    let list = res.docs.map((data) => {
      const userData = data.data();
      return {
        ...userData,
        id: data.id,
      } as UserAccount;
    });
    const isHidden = getState().user.userAccount.isHidden;
    if (!isHidden) {
      list = list.filter((item) => !item?.isHidden);
    }
    dispatch(adminSlice.actions.setUsers(list));
  });
};

export const patchUser =
  (userId: string, updates: object): AppThunk =>
  async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;
    const user = cloneDeep(getState().admin.users).find((user) => user.id === userId);
    const updatedUser = { ...user, ...updates };
    dispatch(adminSlice.actions.setUser(updatedUser));
    return api
      .patch(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/users/${userId}`, updates, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      })
      .then((res) => {
        if (res.status !== 200 && res.status !== 204) {
          dispatch(adminSlice.actions.setUser(user));
        }
      });
  };

export const deleteUser =
  (uid: string): AppThunk =>
  async () => {
    const b = await getUserToken();
    if (!b) return;
    return api.delete(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/users/${uid}`, {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    });
  };

export const deleteEntityType = (uids: string[]) => async (_, getState) => {
  const b = await getUserToken();
  const inboxId = getState().admin.activeInboxId;
  if (!b) return;
  return api.delete(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/entity_types?uids=${uids.join(',')}`,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const deleteDocTypes = async (inboxId: string, uids: string[]) => {
  const b = await getUserToken();
  if (!b) return;
  return api.delete(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/doc_types?uids=${uids.join(',')}`,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const deleteMetadataTypes = (uids: string[]) => async (_, getState) => {
  const b = await getUserToken();
  const inboxId = getState().admin.activeInboxId;
  if (!b) return;
  return api.delete(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/metadata_keys?uids=${uids.join(',')}`,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const deleteTagTypes = (uids: string[]) => async (_, getState) => {
  const b = await getUserToken();
  const inboxId = getState().admin.activeInboxId;
  if (!b) return;
  return api.delete(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/tag_types?uids=${uids.join(',')}`,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const deleteActionType = (uids: string[]) => async (_, getState) => {
  const b = await getUserToken();
  const inboxId = getState().admin.activeInboxId;
  if (!b) return;
  return api.delete(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/action_metadata_keys?uids=${uids.join(
      ','
    )}`,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const generateSignInEmail =
  (userEmail: string): AppThunk =>
  async (_, getState) => {
    const tenantId = getState().tenant.tenantId;
    return api
      .post(
        `${import.meta.env.VITE_PAPERBOX_LOGIN_URL}/tenants/${tenantId}/users/${userEmail}/magic_link`,
        null,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
        }
      )
      .then(() => {});
  };

export const createNewUser = (email: string) => async () => {
  const b = await getUserToken();
  if (!b) return;
  return await api.post(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/users`,
    { email: email },
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const getInboxes = (): AppThunk => async (dispatch, getState) => {
  const b = await getUserToken();
  const tenantId = getState().tenant.tenantId;
  if (!b) return;
  dispatch(adminSlice.actions.setIsInboxesLoading(true));

  try {
    const ref = collection(db, `tenants/${tenantId}/inboxes/`);
    const res = await getDocs(ref);
    const inboxes = res.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as IClientInbox;
    });
    const mappedInboxes: IClientInbox[] = inboxes
      .map((inbox: any) => inboxRawToClient(inbox, tenantId))
      .sort((a, b) => a.settings.name.localeCompare(b.settings.name));
    dispatch(adminSlice.actions.setInboxes(mappedInboxes));
    dispatch(adminSlice.actions.setIsInboxesLoading(false));
  } catch (error) {
    console.error('Error fetching inboxes:', error);
    dispatch(adminSlice.actions.setIsInboxesLoading(false));
  }
};

export const getTenantConfig = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const ref = doc(db, `tenants/${tenantId}/`);
  getDoc(ref).then((res) => {
    const tenantDetails = camelcaseKeys(res.data(), {
      deep: true,
    }) as IClientTenant;
    dispatch(adminSlice.actions.setTenantDetails(tenantDetails));
  });
};

export const getAdminMetadataTypesForInbox = async (tenantId: string, inboxId: string) => {
  if (getMetadataTypesSub) getMetadataTypesSub();
  const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/metadata_keys`);
  const res = await getDoc(ref);
  if (!res.exists() || res.data() == null) return;
  let mappedData: IClientMetadata[] = Object.entries(res.data())
    .map(([key, value]) => {
      return metadataRawToClient({ id: key, ...value });
    })
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((st) => !st.isArchived);

  mappedData = camelcaseKeys(mappedData, { deep: true });
  return mappedData;
};

let getMetadataTypesSub: Unsubscribe;

export const listenAdminMetadataTypesForInbox =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    if (getMetadataTypesSub) getMetadataTypesSub();
    const tenantId = getState().tenant.tenantId;
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/metadata_keys`);
    getMetadataTypesSub = onSnapshot(ref, (res) => {
      if (!res.exists() || res.data() == null) return;
      let mappedData: IClientMetadata[] = Object.entries(res.data())
        .map(([key, value]) => {
          return metadataRawToClient({ id: key, ...value });
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((st) => !st.isArchived);

      mappedData = camelcaseKeys(mappedData, { deep: true });
      dispatch(adminSlice.actions.setInboxMetadataTypes(mappedData));
    });
  };
let getTagTypesSub: Unsubscribe;

export const listenAdminTagTypesForInbox =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    if (getTagTypesSub) getTagTypesSub();
    const tenantId = getState().tenant.tenantId;
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/tag_types`);
    dispatch(adminSlice.actions.setInboxTagTypes([]));

    getTagTypesSub = onSnapshot(ref, (res) => {
      if (!res.exists() || res.data() == null) return;
      const mappedData: TagType[] = Object.entries(res.data())
        .map(([key, value]) => {
          return {
            id: key,
            ...camelcaseKeys(value),
          } as TagType;
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((st) => !st.isArchived);

      dispatch(adminSlice.actions.setInboxTagTypes(mappedData));
    });
  };
let getApprovalChecksSub: Unsubscribe;

export const listenAdminApprovalChecksForInbox =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    if (getApprovalChecksSub) getApprovalChecksSub();
    const tenantId = getState().tenant.tenantId;
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/approval_checks`);
    dispatch(adminSlice.actions.setInboxApprovalChecks([]));

    getTagTypesSub = onSnapshot(ref, (res) => {
      if (!res.exists() || res.data() == null) return;
      const keys = Object.keys(res.data());

      dispatch(adminSlice.actions.setInboxApprovalChecks(keys));
    });
  };

let getActionTypesSub;

export const listenAdminActionTypesForInbox =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    if (getActionTypesSub) getActionTypesSub();
    const tenantId = getState().tenant.tenantId;
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/action_metadata_keys`);
    getActionTypesSub = onSnapshot(ref, (res) => {
      if (!res.exists() || res.data() == null) return;
      const mappedData = Object.entries(res.data())
        .map(([key, value]) => {
          return bounceFieldRawToClient({
            id: key,
            ...value,
          });
        })
        .filter((option) => !option.isArchived)
        .sort((a, b) => a.name.localeCompare(b.name));
      dispatch(adminSlice.actions.setInboxActionTypes(mappedData));
    });
  };

export const getAdminDocTypesForInbox = async (tenantId: string, inboxId: string) => {
  const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/doc_types`);
  const res = await getDoc(ref);
  const mappedData = Object.entries(res.data())
    .map(([key, value]) => {
      return docTypeRawToClient({
        id: key,
        ...value,
      });
    })
    .sort((a, b) => {
      if (b.id === '@PB_NOTYPE') {
        return -1;
      }
        return a.name.localeCompare(b.name);
    })
    .filter((e) => !e.isPrivate && !e.isArchived);
  return mappedData;
};

let getInboxDocTypesSub;
export const listenAdminDocTypesForInbox =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    if (getInboxDocTypesSub) getInboxDocTypesSub();
    const tenantId = getState().tenant.tenantId;
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/doc_types`);
    getInboxDocTypesSub = onSnapshot(ref, (res) => {
      if (!res.exists() || res.data() == null) return;
      const mappedData = Object.entries(res.data())
        .map(([key, value]) => {
          return docTypeRawToClient({
            id: key,
            ...value,
          });
        })
        .sort((a, b) => {
          if (b.id === '@PB_NOTYPE') {
            return -1;
          }
            return a.name.localeCompare(b.name);
        })
        .filter((e) => !e.isPrivate && !e.isArchived);
      dispatch(adminSlice.actions.setInboxDocTypes(mappedData));
    });
  };

let getConnectorsSnapshot;
export const listenConnectors = (): AppThunk => async (dispatch, getState) => {
  const b = await getUserToken();
  if (!b) return;
  if (getConnectorsSnapshot) getConnectorsSnapshot();
  const tenantId = getState().tenant.tenantId;
  const ref = collection(db, `tenants/${tenantId}/connectors`);

  getConnectorsSnapshot = onSnapshot(ref, (res) => {
    const connectors = res.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as IRawConnector;
    });
    dispatch(adminSlice.actions.setConnectors(connectors));
  });
};

let getWebhooksSnapshot;

export const listenWebhooks = (): AppThunk => async (dispatch, getState) => {
  const b = await getUserToken();
  if (!b) return;
  if (getWebhooksSnapshot) getWebhooksSnapshot();
  const tenantId = getState().tenant.tenantId;
  const ref = collection(db, `tenants/${tenantId}/webhooks`);

  getWebhooksSnapshot = onSnapshot(ref, (res) => {
    const webhooks = res.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as AdminWebhook;
    });
    dispatch(adminSlice.actions.setWebhooks(webhooks ?? []));
  });
};
let getEndpointsSnapshot;

export const listenEndpoints = (): AppThunk => async (dispatch, getState) => {
  const b = await getUserToken();
  if (!b) return;
  if (getEndpointsSnapshot) getEndpointsSnapshot();
  const tenantId = getState().tenant.tenantId;
  const ref = collection(db, `tenants/${tenantId}/endpoints`);

  getEndpointsSnapshot = onSnapshot(ref, (res) => {
    const endpoints = res.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as AdminEndpoint;
    });
    dispatch(adminSlice.actions.setEndpoints(endpoints));
  });
};
export const getWebhookServerSideValues = (): AppThunk => async (dispatch) => {
  const b = await getUserToken();
  if (!b) return;

  return api
    .get(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/webhooks/server-side-values`, {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    })
    .then((res) => {
      let data = cloneDeep(res.data.results) as WebhookServerSideValue[];
      data = data.filter((e) => e.id !== '@PB_SECRET');
      dispatch(adminSlice.actions.setWebhookServerSideValues(data));
    });
};

export const getMasterdataTablesForInboxStatic = async (inboxId: string, tenantId: string) => {
  const ref = collection(db, `tenants/${tenantId}/inboxes/${inboxId}/tables`);
  const tables = [];

  await getDocs(ref).then((res) => {
    tables.push(...res.docs.map((d) => ({ id: d.id, name: d.data().name, type: d.data().type ?? '' })));
  });
  return tables;
};
export const listenMasterdataTablesForInbox =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;
    const tablesRef = collection(db, `tenants/${tenantId}/inboxes/${inboxId}/tables`);

    // Top-level listener for all tables within the inbox
    const unsubscribeTables = onSnapshot(
      tablesRef,
      async (tablesSnapshot) => {
        try {
          const tableIds = new Set(tablesSnapshot.docs.map((doc) => doc.id));
          const tableSnapshots = cloneDeep(getState().subs.adminMasterdataSubs);
          const versionSnapshots = cloneDeep(getState().subs.adminMasterdataVersionSubs);

          // If no tables are found, reset everything
          if (tableIds.size === 0) {
            // Unsubscribe from all existing listeners
            Object.values(tableSnapshots).forEach((unsubscribe) => unsubscribe());
            Object.values(versionSnapshots).forEach((unsubscribe) => unsubscribe());

            dispatch(adminSlice.actions.setInboxMasterdataTables([]));
            dispatch(adminSlice.actions.setInboxMasterdataVersions([]));
            dispatch(subsSlice.actions.setAdminMasterdataSubs({}));
            dispatch(subsSlice.actions.setAdminMasterdataVersionSub({}));
            return;
          }

          // Process each table
          for (const tableId of tableIds) {
            const tableRef = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/tables/${tableId}`);

            // Unsubscribe from existing listeners for this table if they exist
            if (tableSnapshots[inboxId + tableId]) {
              tableSnapshots[inboxId + tableId]();
              delete tableSnapshots[inboxId + tableId];
            }
            if (versionSnapshots[inboxId + tableId]) {
              versionSnapshots[inboxId + tableId]();
              delete versionSnapshots[inboxId + tableId];
            }

            // Table listener
            tableSnapshots[inboxId + tableId] = onSnapshot(tableRef, (tableSnapshot) => {
              if (!tableSnapshot.exists()) {
                // Table was deleted, remove it from the list
                dispatch(
                  adminSlice.actions.setInboxMasterdataTables(
                    getState().admin.inboxMasterdataTables.filter((t) => t.id !== tableId)
                  )
                );
                dispatch(
                  adminSlice.actions.setInboxMasterdataVersions(
                    getState().admin.inboxMasterdataVersions.filter((v) => v.table_id !== tableId)
                  )
                );
                return;
              }

              // Process table snapshot
              const updatedTable = { id: tableSnapshot.id, ...tableSnapshot.data() } as IRawMasterdataTable;

              // Get current tables from state to ensure we're working with latest
              const currentTables = getState().admin.inboxMasterdataTables || [];
              const tableIndex = currentTables.findIndex((t) => t.id === tableId);

              let newTables;
              if (tableIndex !== -1) {
                // Update existing table
                newTables = [...currentTables];
                newTables[tableIndex] = updatedTable;
              } else {
                // Add new table
                newTables = [...currentTables, updatedTable];
              }

              // Sort and dispatch
              newTables.sort((a, b) => a.name.localeCompare(b.name));
              dispatch(adminSlice.actions.setInboxMasterdataTables(newTables));
            });

            // Versions listener
            const versionRef = collection(
              db,
              `tenants/${tenantId}/inboxes/${inboxId}/tables/${tableId}/versions`
            );
            const filters = [
              where('status', 'in', ['ingesting', 'ready']),
              orderBy('upload_time', 'desc'),
              limit(1),
            ];
            const versionsQuery = query(versionRef, ...filters);

            versionSnapshots[inboxId + tableId] = onSnapshot(versionsQuery, (versionSnapshot) => {
              // Get current versions from state to ensure we're working with latest
              const currentVersions = getState().admin.inboxMasterdataVersions || [];

              if (versionSnapshot.empty) {
                // Remove versions for this table if no versions exist
                const newVersions = currentVersions.filter((v) => v.table_id !== tableId);
                dispatch(adminSlice.actions.setInboxMasterdataVersions(newVersions));
                return;
              }

              // Process version snapshot
              const versionData = versionSnapshot.docs[0].data() as IRawMasterdataVersion;
              const versionIndex = currentVersions.findIndex((v) => v.table_id === versionData.table_id);

              let newVersions;
              if (versionIndex !== -1) {
                // Update existing version
                newVersions = [...currentVersions];
                newVersions[versionIndex] = versionData;
              } else {
                // Add new version
                newVersions = [...currentVersions, versionData];
              }

              dispatch(adminSlice.actions.setInboxMasterdataVersions(newVersions));
            });
          }

          // Update state with listeners
          dispatch(subsSlice.actions.setAdminMasterdataSubs(tableSnapshots));
          dispatch(subsSlice.actions.setAdminMasterdataVersionSub(versionSnapshots));
        } catch (error) {
          console.error('Error in listenMasterdataTablesForInbox:', error);
          // Optionally dispatch an error action or reset state
          dispatch(adminSlice.actions.setInboxMasterdataTables([]));
          dispatch(adminSlice.actions.setInboxMasterdataVersions([]));
        }
      },
      (error) => {
        console.error('Snapshot error in listenMasterdataTablesForInbox:', error);
      }
    );

    // Store unsubscribeTables for later cleanup, if necessary
    dispatch(subsSlice.actions.setAdminMasterdataTablesSub(unsubscribeTables));
  };
let adminEntitySnapshot;
export const listenAdminEntityTypesForInbox =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    if (adminEntitySnapshot) adminEntitySnapshot();
    const tenantId = getState().tenant.tenantId;
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/entity_types`);
    adminEntitySnapshot = onSnapshot(ref, (res) => {
      if (!res.data()) {
        dispatch(adminSlice.actions.setInboxEntityTypes([]));

        return;
      }
      let mappedData: IRawFieldType[] = Object.entries(res.data())
        .map(([key, value]) => {
          return {
            id: key,
            ...value,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      mappedData = mappedData.map((data) => fieldTypeRawToClient(data)).filter((et) => !et.isArchived);
      dispatch(adminSlice.actions.setInboxEntityTypes(mappedData));
    });
  };

export const postInbox = async (content: Partial<IRawInbox>) => {
  const b = await getUserToken();
  if (!b) return;
  return api.post(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes`,
    { settings: content.settings },
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const patchInbox = async (content: Partial<IRawInbox>) => {
  const b = await getUserToken();
  if (!b) return;
  return api.patch(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${content.id}`,
    { settings: content.settings },
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const putConnector = async (connectorId: string, content: IRawConnector) => {
  const b = await getUserToken();
  if (!b) return;
  return api.put(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/connectors/${connectorId}`,
    content,

    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const postConnector = async (content: IRawConnector) => {
  const b = await getUserToken();
  if (!b) return;

  return api.post(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/connectors`, content, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};
export const deleteConnector = async (connectorId: string) => {
  const b = await getUserToken();
  if (!b) return;

  return api.delete(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/connectors/${connectorId}`, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};
export const deleteWebhook = async (webhookId: string) => {
  const b = await getUserToken();
  if (!b) return;

  return api.delete(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/webhooks/${webhookId}`, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};
export const deleteEndpoint = async (endpointId: string) => {
  const b = await getUserToken();
  if (!b) return;

  return api.delete(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/endpoints/${endpointId}`, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export const patchEndpoint = async (endpointId: string, content: Partial<AdminEndpoint>) => {
  const b = await getUserToken();
  if (!b) return;
  return api.put(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/endpoints/${endpointId}`, content, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export const postEndpoint = async (content: IRawEndpoint) => {
  const b = await getUserToken();
  if (!b) return;
  return api.post(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/endpoints`, content, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export const patchWebhook = async (webhookId: string, content: Partial<IRawWebhook>) => {
  const b = await getUserToken();
  if (!b) return;
  return api.put(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/webhooks/${webhookId}`,
    content,

    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const postWebhook = async (content: IRawWebhook) => {
  const b = await getUserToken();
  if (!b) return;

  return api.post(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/webhooks`, content, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

type MasterdataPutPayload = {
  name?: string;
  mapping: object;
  provider_id?: string;
};

export const patchMasterdataTable = async (
  inboxId: string,
  tableId: string,
  payload: MasterdataPutPayload
) => {
  const b = await getUserToken();
  if (!b) return;
  if (!payload) return;

  return api.put(
    `${import.meta.env.VITE_PAPERBOX_MASTERDATA_URL}/inboxes/${inboxId}/tables/${tableId}`,
    payload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

type MasterdataPostPayload = {
  name?: string;
};

export const postMasterdataTable = async (inboxId: string, payload: MasterdataPostPayload) => {
  const b = await getUserToken();
  if (!b) return;
  if (!payload) return;

  return api.post(`${import.meta.env.VITE_PAPERBOX_MASTERDATA_URL}/inboxes/${inboxId}/tables`, payload, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export const patchInboxSettings = (inboxId: string, updates: object) => async () => {
  const b = await getUserToken();
  if (!b) return;
  // inboxId = 'weqew';
  // updates = { false: false };
  if (!updates) return;
  return api.patch(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}`,
    {
      settings: {
        ...updates,
      },
    },
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
type TenantPatchPayload = {
  name: string;
  timezone: string;
  language: string;
  allowedDomains: string[];
  allowedIPs: string[];
  inviteOnly: boolean;
};
export const patchTenantSettings = (updates: TenantPatchPayload) => async () => {
  const b = await getUserToken();
  if (!b) return;
  if (!updates) return;
  return api.patch(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/tenant`,
    {
      ...updates,
    },
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const patchEntityType = async (
  inboxId: string,
  entityTypeId: string,
  payload: Partial<IRawFieldType>
) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  return api.patch(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/entity_types/${entityTypeId}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const postEntityType = async (inboxId: string, payload: Partial<IRawFieldType>) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  return api.post(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/entity_types`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const putEntityType = async (inboxId: string, payload: Partial<IRawFieldType>, id: string) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  return api.put(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/entity_types/${id}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

type MetadataTypePostPayload = {
  name: string;
  provider_id?: string;
  is_sensitive?: boolean;
};

export const postMetadataType = async (inboxId: string, payload: MetadataTypePostPayload) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  return api.post(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/metadata_keys`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const putMetadataType = async (inboxId: string, payload: Partial<IRawMetadata>, id: string) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  return api.put(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/metadata_keys/${id}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

type MetadataTypePatchPayload = {
  name: string;
  provider_id?: string;
  is_sensitive?: boolean;
};

export const patchMetadataType = async (
  inboxId: string,
  metadataTypeId: string,
  payload: MetadataTypePatchPayload
) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  return api.patch(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/metadata_keys/${metadataTypeId}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const postActionType = async (inboxId: string, payload: Partial<IRawBounceField>) => {
  const localPayload = payload;
  delete localPayload.options;
  const b = await getUserToken();
  if (!b) return;
  return api.post(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/action_metadata_keys`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const putActionType = async (inboxId: string, payload: Partial<IRawBounceField>, id: string) => {
  const localPayload = payload;
  delete localPayload.options;
  const b = await getUserToken();
  if (!b) return;
  return api.put(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/action_metadata_keys/${id}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const patchActionType =
  (inboxId: string, actionTypeId: string, payload: Partial<IRawBounceField>) => async () => {
    const localPayload = payload;
    const b = await getUserToken();
    if (!b) return;
    return api.patch(
      `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/action_metadata_keys/${actionTypeId}`,
      localPayload,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      }
    );
  };

type ActionTypeOptionPatchPayload = {
  name: string;
  provider_id?: string;
};

export const patchActionTypeOption = async (
  inboxId: string,
  actionTypeId: string,
  optionId: string,
  payload: ActionTypeOptionPatchPayload
) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  return api.patch(
    `${
      import.meta.env.VITE_PAPERBOX_BACKEND_URL
    }/inboxes/${inboxId}/action_metadata_keys/${actionTypeId}/options/${optionId}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const deleteActionTypeOption =
  (inboxId: string, actionTypeId: string, optionId: string) => async () => {
    const b = await getUserToken();
    if (!b) return;
    return api.delete(
      `${
        import.meta.env.VITE_PAPERBOX_BACKEND_URL
      }/inboxes/${inboxId}/action_metadata_keys/${actionTypeId}/options/${optionId}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      }
    );
  };
type ActionTypeOptionPostPayload = {
  name: string;
  provider_id?: string;
};

export const postActionTypeOption = async (
  inboxId: string,
  actionTypeId: string,
  payload: ActionTypeOptionPostPayload
) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  return api.post(
    `${
      import.meta.env.VITE_PAPERBOX_BACKEND_URL
    }/inboxes/${inboxId}/action_metadata_keys/${actionTypeId}/options`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const putActionTypeOption = async (
  inboxId: string,
  actionTypeId: string,
  payload: Partial<ActionTypeOption>
) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;
  const id = payload.id;
  delete localPayload.id;
  return api.put(
    `${
      import.meta.env.VITE_PAPERBOX_BACKEND_URL
    }/inboxes/${inboxId}/action_metadata_keys/${actionTypeId}/options/${id}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export type TagTypePayload = {
  color: string;
  name: string;
};

export const patchTagType = async (inboxId: string, tagTypeId: string, payload: TagTypePayload) => {
  const b = await getUserToken();
  if (!b) return;
  return api.patch(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/tag_types/${tagTypeId}`,
    payload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const postTagType = async (inboxId: string, payload: TagTypePayload) => {
  const b = await getUserToken();
  if (!b) return;
  return api.post(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/tag_types`, payload, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export const putTagType = async (inboxId: string, payload: Partial<IRawTag>, id: string) => {
  const b = await getUserToken();
  if (!b) return;
  return api.put(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/tag_types/${id}`, payload, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export type DocTypePatchPayload = {
  approval_threshold: number;
  age_threshold: number;
  ocr_threshold: number;
  auto_approve: boolean;
  entity_types: IRawDocTypeField[];
  provider_id?: string;
  table_types?: string[];
  name?: string;
  // rules?: { match?: string; rules?: DocTypeRule[] };
};
export const patchDocType = async (
  inboxId: string,
  docTypeId: string,
  payload: Partial<IRawDocTypeField>
) => {
  const b = await getUserToken();
  console.log(b);
  console.log(inboxId);
  console.log(payload);
  if (!b) return;
  return api.patch(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/doc_types/${docTypeId}`,
    payload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export type DocTypePostPayload = {
  approval_threshold?: number;
  age_threshold?: number;
  auto_approve?: boolean;
  entity_types?: IRawDocTypeField[];
  name: string;
  provider_id?: string;
  rules?: { match?: string; rules?: DocTypeRule[] };
};

export const postDocType = async (inboxId: string, payload: Partial<IRawDocTypeField>) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;

  return api.post(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/doc_types`, localPayload, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export const putDocType = async (inboxId: string, payload: Partial<IRawDocTypeField>, id: string) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;

  return api.put(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/doc_types/${id}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const postSubtype = async (inboxId: string, doctypeId: string, payload: any) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;

  return api.post(
    `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/doc_types/${doctypeId}/subtypes`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export const deleteSubtype = (inboxId: string, doctypeId: string, subtypeId: string) => async () => {
  const b = await getUserToken();
  if (!b) return;

  return api.delete(
    `${
      import.meta.env.VITE_PAPERBOX_BACKEND_URL
    }/inboxes/${inboxId}/doc_types/${doctypeId}/subtypes/${subtypeId}`,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};

export type SubtypePatchPayload = {
  name?: string;
  provider_id?: string;
};

export const patchSubtype = async (
  inboxId: string,
  doctypeId: string,
  subtypeId: string,
  payload: SubtypePatchPayload
) => {
  const localPayload = payload;
  const b = await getUserToken();
  if (!b) return;

  return api.patch(
    `${
      import.meta.env.VITE_PAPERBOX_BACKEND_URL
    }/inboxes/${inboxId}/doc_types/${doctypeId}/subtypes/${subtypeId}`,
    localPayload,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${b}`,
      },
    }
  );
};
export const postTableVersionData = async (inboxId: string, file: File, tableId) => {
  /*Wrap document in formdata*/
  // const formData = new FormData();
  const formData = new FormData();
  formData.append('payload', file);
  formData.append('content_type', 'text/csv');

  const b = await getUserToken();
  if (!b) return;
  const params = {};
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${b}`,
      'Content-Type': 'multipart/form-data',
    },
    params,
  };
  /*Post pdf to backend*/
  return await api.post(
    `${import.meta.env.VITE_PAPERBOX_MASTERDATA_URL}/inboxes/${inboxId}/tables/${tableId}/versions`,

    formData,
    config
  );
};

export const deleteMasterdataTable = (inboxId: string, tableId: string) => async () => {
  const b = await getUserToken();
  if (!b) return;
  return api.delete(`${import.meta.env.VITE_PAPERBOX_MASTERDATA_URL}/inboxes/${inboxId}/tables/${tableId}`, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export const getTableCSV = async (inboxId: string, tableId) => {
  const b = await getUserToken();
  if (!b) return;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${b}`,
    },
  };
  /*Post pdf to backend*/
  return await api.get(
    `${import.meta.env.VITE_PAPERBOX_MASTERDATA_URL}/inboxes/${inboxId}/tables/${tableId}/raw`,
    config
  );
};

export const exportSliceToJson = (): AppThunk => async (_, getState) => {
  const serializedState = JSON.stringify(getState().admin);
  const activeInboxId = getState().admin.activeInboxId;
  const inboxes = getState().admin.inboxes;
  const tenantId = getState().tenant.tenantId;
  const activeInboxName = inboxes.find((inbox) => inbox.id === activeInboxId)?.settings.name || 'Inbox';
  const env = getCurrentEnvCode();
  const blob = new Blob([serializedState], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${env}-${tenantId}-${activeInboxName}-configuration.json`; // Filename for the download
  document.body.appendChild(link); // Required for Firefox
  link.click();
  document.body.removeChild(link);
};

export default adminSlice.reducer;
