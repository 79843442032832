import {
  IClientEndpoint,
  IClientEndpointBrio,
  IClientEndpointHTTP,
} from "./endpoint.ts";

interface IRawHTTPWebhook {
  id?: string;
  name: string;
  active: boolean;
  depends_on: string[];
  events: string[];
  inboxes: string[] | Record<string, any>;
  action_types: string[];
  endpoint_id: string;
}

export type IRawWebhook = IRawHTTPWebhook;

export interface IClientWebhookHTTP {
  id: string;
  name: string;
  active: boolean;
  dependsOn: string[];
  events: string[];
  inboxes: { id: string; tableIds?: string[] }[];
  actionTypes: string[];
  endpoint: IClientEndpointHTTP | IClientEndpointBrio;
}

export type IClientWebhook = IClientWebhookHTTP;

export const webhookRawToClient = (
  rawWebhook: IRawWebhook,
  endpoints: IClientEndpoint[]
): IClientWebhook => {
  const endpoint = endpoints.find((e) => e.id === rawWebhook.endpoint_id);
  const item = {
    id: rawWebhook.id,
    name: rawWebhook.name,
    active: rawWebhook.active,
    dependsOn: rawWebhook.depends_on,
    events: rawWebhook.events,
    inboxes: null,
    actionTypes: rawWebhook.action_types,
    endpoint: endpoint,
  };
  if (rawWebhook.inboxes && !Array.isArray(rawWebhook.inboxes)) {
    item.inboxes = Object.entries(rawWebhook.inboxes).map(([inboxId, val]) => ({
      id: inboxId,
      tableIds: val.table_ids,
    }));
  } else {
    item.inboxes = rawWebhook.inboxes?.map((e) => ({ id: e })) ?? [];
  }

  return item;
};

export const webhookClientToRaw = (webhook: IClientWebhook): IRawWebhook => {
  const data = {
    name: webhook.name,
    active: webhook.active,
    depends_on: webhook.dependsOn,
    events: webhook.events,
    inboxes: {},
    action_types: webhook.actionTypes,
    endpoint_id: webhook.endpoint.id,
  };
  if (webhook.inboxes) {
    webhook.inboxes.forEach((inbox) => {
      data.inboxes[inbox.id] = { table_ids: inbox.tableIds };
      if (!inbox.tableIds) delete data.inboxes[inbox.id].table_ids;
    });
  }

  return data;
};
